<template>
  <v-container fluid>
    <!-- <Breadcrumbs /> -->
    <!-- Knowlarity Creds Input -->
    <v-flex>
      <v-card class="rounded secondarygrad mx-4">
        <v-row style="margin: 0px">
          <v-col>
            <v-card
              color="transparent"
              elevation="0"
              style="padding: 10px; text-align: center !important"
            >
              <v-card-text style="margin-bottom: 25px">
                <div
                  align="center"
                  style="margin-bottom: 10px; margin-top: 20%"
                >
                  <h1 style="margin-bottom: 40px">Godial + Exotel</h1>
                </div>
                <div style="padding: 5px 15px; text-align: center">
                  <h5
                    style="
                          line-height: 17px;
                          font-weight: 500;
                          font-size: 14px;
                        "
                  >
                    Integrate GoDial with Exotel and enjoy features like Call
                    Recording, Call number masking and more. Find these details
                    from your exotel partner and enable it.
                  </h5>

                  <div style="text-align: left">
                    <p
                      style="
                            margin-top: 20px;
                            font-size: 18px;
                            font-weight: 600;
                            margin-bottom: 10px;
                          "
                    >
                      Features
                    </p>
                    1. Call Recording
                    <br />
                    2. Number masking
                    <br />
                    3. Incoming Calls (Integration under development)
                  </div>
                </div>
              </v-card-text>
              <v-footer color="transparent" absolute>
                <v-flex style="text-align: center !important">
                  <p
                    style="
                          font-size: 12px;
                          opacity: 0.9;
                          margin-bottom: 0px;
                        "
                  >
                    Feel free to reach out to us at
                    <a href="mailto:support@godial.cc">support@godial.cc</a>
                    if you are interested in these features
                  </p>
                </v-flex>
              </v-footer>
            </v-card>
          </v-col>
          <v-col>
            <v-card flat class="transparent">
              <v-flex xs12 sm10 offset-sm1 md10 offset-md1>
                <v-layout row wrap>
                  <v-flex d-flex justify-center style="padding-top: 22px"
                    >Enable Exotel
                  </v-flex>
                  <v-flex d-flex justify-center>
                    <v-switch
                      v-model="exotel.enabled"
                      color="primary"
                    ></v-switch>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-card>
            <v-card
              color="transparent"
              elevation="0"
              style="padding: 15px 50px; text-align: center"
            >
              <v-card-title primary-title class="justify-center"
                >Exotel Credentials</v-card-title
              >

              <v-flex xs12 sm10 offset-sm1 md10 offset-md1>
                <v-text-field
                  v-bind:disabled="!exotel.enabled"
                  v-model="exotel.apiKey"
                  label="Exotel Api Key"
                  prepend-inner-icon="vpn_key"
                  dense
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm10 offset-sm1 md10 offset-md1>
                <v-text-field
                  v-bind:disabled="!exotel.enabled"
                  v-model="exotel.apiToken"
                  label="Exotel Api Token"
                  dense
                  outlined
                  prepend-inner-icon="perm_identity"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm10 offset-sm1 md10 offset-md1>
                <v-text-field
                  v-bind:disabled="!exotel.enabled"
                  v-model="exotel.sid"
                  label="Exotel Sid"
                  dense
                  outlined
                  prepend-inner-icon="stay_primary_portrait"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm10 offset-sm1 md10 offset-md1>
                <v-text-field
                  v-bind:disabled="!exotel.enabled"
                  v-model="exotel.callerId"
                  label="Exotel CallerId"
                  dense
                  outlined
                  prepend-inner-icon="accessibility"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm10 offset-sm1 md10 offset-md1>
                <v-autocomplete
                  v-bind:disabled="!exotel.enabled"
                  v-model="exotel.subdomain"
                  :items="exotelSubdomains"
                  item-text="text"
                  item-value="value"
                  label="Exotel Subdomain"
                  outlined
                  dense
                  return-object
                ></v-autocomplete>
              </v-flex>

              <v-flex xs12 sm10 offset-sm1 md10 offset-md1>
                <v-autocomplete
                  v-bind:disabled="!exotel.enabled"
                  v-model="selectedList"
                  :items="lists"
                  item-text="name"
                  item-value="id"
                  label="Incoming Call List"
                  outlined
                  dense
                  return-object
                ></v-autocomplete>
              </v-flex>

              <v-card-text>
                <v-flex
                  xs12
                  sm10
                  offset-sm1
                  md10
                  offset-md1
                  style="margin-bottom: 10px"
                >
                  <v-btn
                    color="primarygrad"
                    dark
                    block
                    @click="setExotelCompany"
                    >Save</v-btn
                  >
                </v-flex>
              </v-card-text>

              <!-- <v-card color="primarygrad">
                    <v-row align="center">
                      <v-col
                        align="center"
                        class="white--text"
                        md="2"
                        cols="12"
                      >
                        <h2>Webhook</h2>
                      </v-col>
                      <v-col md="10" cols="12">
                        <v-row class="text-center justify-center align-center">
                          <v-col cols="6" md="4">
                            {{
                              `${ENDPOINT}/cloudTelephonies/${item.id}/exotel-passthru-webhook/${token}`
                            }}
                          </v-col>
                          <v-col cols="6" md="4">
                            <v-btn dark block @click="copy('')"
                              >COPY LINK</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col md="10" cols="12">
                        <v-row class="text-center justify-center align-center">
                          <v-col cols="6" md="4">
                            {{
                              `${ENDPOINT}/cloudTelephonies/${item.id}/exotel-in-push-notification/${token}`
                            }}
                          </v-col>
                          <v-col cols="6" md="4">
                            <v-btn dark block @click="copy('')"
                              >COPY LINK</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card> -->

              <!-- <h4>
                    webhook:
                    <span
                      class="pl-3 font-weight-medium secondary--text text--lighten-3"
                    >
                      {{
                        `${ENDPOINT}/cloudTelephonies/${item.id}/exotel-passthru-webhook/${token}`
                      }}
                    </span>
                  </h4>

                  <h4>
                    Popup hook:
                    <span
                      class="pl-3 font-weight-medium secondary--text text--lighten-3"
                    >
                      {{
                        `${ENDPOINT}/cloudTelephonies/${item.id}/exotel-in-push-notification/${token}`
                      }}
                    </span>
                  </h4> -->
            </v-card>
          </v-col>
        </v-row>
      </v-card>

      <div style="padding: 10px"></div>

      <v-container
        class="pa-0 ma-0"
        fluid
        v-if="exotelObjectId != ''"
        style="padding: 10px"
      >
        <v-card color="primarygrad">
          <v-row align="center">
            <v-col align="center" class="white--text" md="2" cols="12">
              <h2>Web hook</h2>
            </v-col>
            <v-col md="10" cols="12">
              <v-row class="text-center justify-center align-center">
                <v-col cols="12" md="8">
                  {{
                    `${ENDPOINT}/cloudTelephonies/${exotelObjectId}/exotel-passthru-webhook/${token}`
                  }}
                </v-col>

                <v-col cols="6" md="2">
                  <v-btn
                    dark
                    block
                    @click="
                      copy(
                        `${ENDPOINT}/cloudTelephonies/${exotelObjectId}/exotel-passthru-webhook/${token}`
                      )
                    "
                    >COPY LINK</v-btn
                  >
                </v-col>
              </v-row>
              <v-snackbar
                v-model="snackbar"
                :bottom="true"
                :right="true"
                timeout="2000"
                >Copied!</v-snackbar
              >
            </v-col>
          </v-row>
        </v-card>
      </v-container>

      <div style="padding: 14px!important"></div>

      <v-container
        class="pa-0 ma-0"
        fluid
        v-if="exotelObjectId != ''"
        style="padding: 10px"
      >
        <v-card color="primarygrad">
          <v-row align="center">
            <v-col align="center" class="white--text" md="2" cols="12">
              <h2>Popup hook</h2>
            </v-col>
            <v-col md="10" cols="12">
              <v-row class="text-center justify-center align-center">
                <v-col cols="12" md="8">
                  {{
                    `${ENDPOINT}/cloudTelephonies/${exotelObjectId}/exotel-in-push-notification/${token}`
                  }}
                </v-col>

                <v-col cols="6" md="2">
                  <v-btn
                    dark
                    block
                    @click="
                      copy(
                        `${ENDPOINT}/cloudTelephonies/${exotelObjectId}/exotel-in-push-notification/${token}`
                      )
                    "
                    >COPY LINK</v-btn
                  >
                </v-col>
              </v-row>

              <v-snackbar
                v-model="snackbar"
                :bottom="true"
                :right="true"
                timeout="2000"
                >Copied!</v-snackbar
              >
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </v-flex>

    <!-- Loader -->
    <v-dialog v-model="loader" persistent width="300">
      <v-card color="primary white--text" dark>
        <v-card-text style="padding: 18px 25px">
          <h3 style="margin-bottom: 5px; font-weight: 400; font-size: 16px">
            Please Wait...
          </h3>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      selectedList: {},
      exotel: {
        apiKey: "",
        apiToken: "",
        sid: "",
        subdomain: { text: "Mumbai", value: "api.exotel.com" },
        callerId: "",
        enabled: false,
        incomingListId: "",
      },
      lists: [],
      exotelSubdomains: [
        { text: "Mumbai", value: "api.exotel.com" },
        // { text: "Singapore", value: "api.exotel.com" },
      ],
      exotelIdAvailable: false,
      loader: false,
      token: "",
      exotelObjectId: "",
      snackbar: false,
      // knowlarityExists: false,
    };
  },

  computed: {
    ...mapState(["ENDPOINT", "companyDetails", "selectedTeam"]),
  },
  created() {
    this.refresh();
  },
  watch: {},
  methods: {
    copy(link) {
      const self = this;
      this.$copyText(link).then(
        function(e) {
          self.snackbar = true;
          console.log(e);
        },
        function(e) {
          console.log(e);
        }
      );
    },
    generateToken() {
      this.$http
        .get(
          `${this.ENDPOINT}/externals/token?access_token=${this.$store.getters.user.token}`
        )
        .then((response) => {
          this.token = response.body.id;
        })
        .catch((err) => {
          this.$swal({ type: "error", text: err.body.error.message });
        });
    },
    async refresh() {
      await this.generateToken();
      await this.fetchLists();
      await this.getExotelDetails();
    },
    setExotelCompany() {
      if (this.exotel.apiKey == "") {
        return this.$swal({
          type: "warning",
          text: "Api Key Can't be Blank",
        });
      }

      if (this.exotel.apiToken == "") {
        return this.$swal({
          type: "warning",
          text: "Api token Can't be Blank",
        });
      }

      if (this.exotel.sid == "") {
        return this.$swal({
          type: "warning",
          text: "SID Can't be Blank",
        });
      }

      if (this.exotel.callerId == "") {
        return this.$swal({
          type: "warning",
          text: "Caller Id Can't be Blank",
        });
      }

      if (this.exotel.subdomain == "") {
        return this.$swal({
          type: "warning",
          text: "Sub domain Can't be Blank",
        });
      }

      if (this.exotel.selectedList == "") {
        return this.$swal({
          type: "warning",
          text: "Please select a list",
        });
      }

      let cloudObj = {
        companyId: this.$store.state.companyDetails.id,
      };

      //let provider = "Exotel"

      this.exotel.incomingListId = this.selectedList.id;

      cloudObj.config = this.exotel;

      cloudObj.provider = "Exotel";
      cloudObj.enabled = true;

      console.log("cloudObj", cloudObj);

      if (!this.exotelIdAvailable) {
        this.$http
          .post(`${this.ENDPOINT}/cloudTelephonies`, cloudObj)
          .then((respo) => {
            this.refresh();
          })
          .catch((err) => {
            console.log("err", err);
          });
      } else {
        this.$http
          .put(
            `${this.ENDPOINT}/cloudTelephonies/${this.exotelObjectId}`,
            cloudObj
          )
          .then((respo) => {
            this.refresh();
            console.log("respo", respo);
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    },
    async getExotelDetails() {
      var resp = await this.$http.get(
        `${this.ENDPOINT}/cloudTelephonies?filter[where][companyId]=${this.companyDetails.id}&filter[where][provider]=Exotel`
      );

      console.log("response exotel get", resp.body);

      if (resp.body.length > 0) {
        this.exotel = resp.body[0].config;

        // console.log("this.lists", this.lists);

        this.selectedList = this.lists.find((l) => {
          return l.id == this.exotel.incomingListId;
        });

        console.log("this.selectedList", this.selectedList);

        //this.selectedList = this.exotel.incomingListId;
        this.exotelIdAvailable = true;
        this.exotelObjectId = resp.body[0].id;
      } else {
        this.exotelIdAvailable = false;
      }
    },
    // objAssignExotel() {
    //   Object.assign(this.exotel, this.companyDetails.exotel);
    // },
    // exotelExistsCheck() {
    //   if (
    //     typeof this.companyDetails.exotel != "undefined" &&
    //     this.companyDetails.exotel.enabled == true
    //   ) {
    //     this.exotelExists = true;
    //   } else {
    //     this.exotelExists = false;
    //   }
    // },
    getTeamIds() {
      var teams = [];
      if (this.selectedTeam == "allTeams") {
        teams = _.map(this.teams, "id");
      } else {
        teams.push(this.selectedTeam);
      }

      teams = _.without(teams, "allTeams");

      return teams;
    },
    async fetchLists() {
      try {
        this.lists = [];
        var response = [];

        var teamIds = this.getTeamIds();

        // Teams sections
        response = await this.$http.post(`${this.ENDPOINT}/lists/fetch`, {
          teams: teamIds,
        });
        this.lists = response.body;

        if (this.lists.length < 1) {
          this.loader = false;
          return;
        }
      } catch (e) {
        this.loader = false;
        console.log("error in list fetch", e);
      }
    },
  },
};
</script>

<style></style>
